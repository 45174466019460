body {
    background: #b8beca;
    margin: 0px;
    max-width: 80em;
}

#root {
    height: 100%
}

a.icon {
    color: black;
    margin: 5px;
    text-decoration: none;
}

a.icon span{
    font-family: 'Yanone Kaffeesatz';
    font-weight: 700;
    font-size: x-large;
}

a.icon img, a.icon svg{
    height: 1.5em;
    vertical-align: text-bottom;
}

a .intro {
    color:black;
    font-family: 'Yanone Kaffeesatz';
    font-size: large;
}

h1 {
    font-family: 'Yanone Kaffeesatz';
    font-weight: 700;
    margin-left: 5px;
    display: inline;
    vertical-align: sub;
}

h2 {
    font-family: 'Yanone Kaffeesatz';
    font-weight: 700;
    display: inline;
    vertical-align: sub;
    size:
}

div {
    font-family: sans-serif;
    font-size: medium
}

.page, .player{
    padding-top: 50px;
    padding-left: 50px;
}

.player, .player div{
    margin-left: auto;
    margin-right: auto;
}

.deepzoom {
    position: absolute;
    top: 50px;
    height: calc(100% - 50px);
    width: 100%
}

svg.profile {
    position: fixed;
    min-height: 100%;
    min-width: 100%;
}

svg.profileSmall {
    position: fixed;
    height: 50px;
    min-width: 100%;
}

#hextiles {
    filter: drop-shadow(2px 4px 6px black);
}

/* yanone-kaffeesatz-700 - latin */
@font-face {
    font-family: 'Yanone Kaffeesatz';
    font-style: normal;
    font-weight: 700;
    src: local('Yanone Kaffeesatz Bold'), local('YanoneKaffeesatz-Bold'),
    url('../fonts/yanone-kaffeesatz-v10-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/yanone-kaffeesatz-v10-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* yanone-kaffeesatz-regular - latin */
@font-face {
    font-family: 'Yanone Kaffeesatz';
    font-style: normal;
    font-weight: 400;
    src: local('Yanone Kaffeesatz Regular'), local('YanoneKaffeesatz-Regular'),
    url('../fonts/yanone-kaffeesatz-v10-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/yanone-kaffeesatz-v10-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@supports not (shape-outside:circle(50%)){
    .intro {
        position: absolute;
        padding-right: 75px;
        padding-left: 75px;
        text-align: justify;
    }
}

